exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applestore-js": () => import("./../../../src/pages/applestore.js" /* webpackChunkName: "component---src-pages-applestore-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-corporate-js": () => import("./../../../src/pages/corporate.js" /* webpackChunkName: "component---src-pages-corporate-js" */),
  "component---src-pages-data-deletion-js": () => import("./../../../src/pages/data-deletion.js" /* webpackChunkName: "component---src-pages-data-deletion-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-googlestore-js": () => import("./../../../src/pages/googlestore.js" /* webpackChunkName: "component---src-pages-googlestore-js" */),
  "component---src-pages-hotels-js": () => import("./../../../src/pages/hotels.js" /* webpackChunkName: "component---src-pages-hotels-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-redirect-to-store-js": () => import("./../../../src/pages/redirect-to-store.js" /* webpackChunkName: "component---src-pages-redirect-to-store-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

